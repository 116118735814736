import React, { useCallback, useState } from 'react';

import {
  ArrayField,
  Datagrid,
  DateField,
  NumberField,
  TextField,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Grid,
  CardContent,
  Typography,
  Divider,
  Fade,
} from '@mui/material';

import { DateTime } from 'luxon';

import { isArray } from 'lodash';

import useEntityData from '../../../../hooks/useEntityData';

import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';
import actions from '../../../../constants/actions';

import PurchaseButton from '../../../layout/button/PurchaseButton';
import FinancialSummary from '../../../../customFields/Charts/FinancialSummary';
import { SubSubNavigation } from '../../../layout/SubNavigation';
import { StyledSubTab, StyledTabs } from '../../../layout/Tab';
import { overviewFinancialFields, overviewKeyRatiosFields, financialDataFields } from '../../../../constants/financialDataFeatures';

export type IFinancialData = {
  id: string;
  createdAt: string;
  accountPeriod: string;
  realEstate: {
    numberRealEstate?: number,
    numberBuildings?: number,
    assessedValueTotal?: number,
    averageOwnedPartPercent?: number,
    assessedValueBuilding?: number,
    assessedValueLand?: number,
    assessedValueOwnedPart?: number,
    detailRealEstate: {
      description?: string
      ownerId?: string
      propertyType?: string
      numberOwners?: number
      ownedPartPercent?: number
      areal?: string
      county?: string
      community?: string
      parish?: string
    }[]
  };
  branch: {
    text?: string,
    netMargin?: string, // Branch, net margin (%) latest year
    rateOfReturn?: string, // Branch, rate of return (times) latest year
    degreeOfDebt?: string, // Branch, degree of debt (times) latest year
    solidity?: string, // Branch, solidity (%) latest year
    quickRatio?: string, // Branch, quick ratio (%) latest year
    riskBuffer?: string, // Branch, risk buffer (%) latest year
  };
  records: {
    turnoverPerEmployee?: number;
    acceptedBankOverdraft?: number;
    accountShortage?: string;
    accountShortageText?: string;
    accountantObligation?: string;
    accountsType?: string;
    agreedSeverancePay?: boolean;
    bsAccountsPayable?: number;
    bsAccountsReceivablesCorporateGroup?: number;
    bsAccountsReceivablesGroup?: number;
    bsAccountsReceivablesTrade?: number;
    bsCapitalExpensesForResAndDev?: number;
    bsCashAndBankBalances?: number;
    bsEqupimentToolsAndInstallations?: number;
    bsGoodwill?: number;
    bsGroupContribution?: number;
    bsGroupShares?: number;
    bsInventories?: number;
    bsLandAndBuildings?: number;
    bsLiabilitiesToCreditInstit?: number;
    bsLoanOwners?: number;
    bsLongTermBondLoan?: number;
    bsLongTermLiabilitiesGroupAndAssocComp?: number;
    bsLongTermLiabilitiesToCreditInstit?: number;
    bsMachinesInventory?: number;
    bsMinorityInterests?: number;
    bsNetIncome?: number;
    bsOtherAccountsReceivables?: number;
    bsOtherCurrentAssets?: number;
    bsOtherFinancialAssets?: number;
    bsOtherIntangibleAssets?: number;
    bsOtherLongTermLiabilities?: number;
    bsOtherMaterialDepreciation?: number;
    bsOtherShareHolderEquity?: number;
    bsOtherShortTermLiabilities?: number;
    bsOtherTangibleAssetsNonDepreciated?: number;
    bsPatents?: number;
    bsPlantAndMachinery?: number;
    bsProvisions?: number;
    bsRetainedEarnings?: number;
    bsRevaluationReserve?: number;
    bsShareCapital?: number;
    bsSharePremiumReserve?: number;
    bsShareholderContribution?: number;
    bsShortTermInvestments?: number;
    bsShortTermLiabilitiesGroupAndAssocComp?: number;
    bsSubscribedCapitalUnpaid?: number;
    bsTotalAccountsReceivable?: number;
    bsTotalAssets?: number;
    bsTotalCurrentAssets?: number;
    bsTotalCurrentLiabilities?: number;
    bsTotalDividendsCapacity?: number;
    bsTotalEquity?: number;
    bsTotalEquityAndLiabilities?: number;
    bsTotalFinancialAssets?: number;
    bsTotalFixedAssets?: number;
    bsTotalIntangibleAssets?: number;
    bsTotalInventories?: number;
    bsTotalLongTermDebts?: number;
    bsTotalTangibleAsstes?: number;
    bsUntaxedReserves?: number;
    bsWorkOnContract?: number;
    changeDate?: string;
    companyId?: string;
    conditionalEquity?: number;
    currency?: string;
    depreciationOfAdministrationCosts?: number;
    depreciationOfOtherCosts?: number;
    depreciationOfResearchAndDevelopmentCosts?: number;
    depreciationOfSaleCosts?: number;
    depreciationOfSoldGoods?: number;
    depreciationUnspecifiedCosts?: number;
    dividend?: number;
    employeesSalary?: number;
    floatingCharge?: number;
    fromDate?: string;
    includingPerformanceBonusToOtherEmployees?: number;
    kpiAccountsReceivablesTurnoverPercent?: number;
    kpiCapitalTurnoverRatio?: number;
    kpiCurrentLiabilitiesTurnoverPercent?: number;
    kpiDegreeOfDebt?: number;
    kpiDuPontModelPercent?: number;
    kpiEbitda?: number;
    kpiEbitdaMarginPercent?: number;
    kpiEquityRatioPercent?: number;
    kpiInventoriesTurnoverPercent?: number;
    kpiOppResultNumberOfEmployees?: number;
    kpiProfitMarginPercent?: number;
    kpiQuickRatioPercent?: number;
    kpiReturnOnCapitalEmployedPercent?: number;
    kpiReturnOnEquityPercent?: number;
    kpiReturnOnWorkingCapitPercent?: number;
    kpiRiskBufferTotalCapitalPercent?: number;
    kpiWorkingCapital?: number;
    months?: number;
    netInterestFinance?: number;
    notUsedBankOverdraft?: number;
    numberOfEmployees?: number;
    comment?: string;
    numberOfShares?: number;
    otherAssetsPledged?: number;
    otherContingentLiabilities?: number;
    payrollOverhead?: number;
    plAdminCosts?: number;
    plChangeInventoryOfWorkInProgress?: number;
    plCostOfGoodsSold?: number;
    plDeprAndWriteDowns?: number;
    plEbit?: number;
    plExternalInterestExpenditures?: number;
    plExternalInterestIncome?: number;
    plExtraordinaryIncome?: number;
    plExtraoridinaryExpenditures?: number;
    plFinancialInterestGroupIncome?: number;
    plGoodsForResale?: number;
    plGrossProfit?: number;
    plGroupContribution?: number;
    plInternalInterestExpenditures?: number;
    plItemsAffectCompare?: number;
    plMinorityInterestAndProfit?: number;
    plNetOperatingIncome?: number;
    plNetProfitLoss?: number;
    plOtherAppropriations?: number;
    plOtherExternalCosts?: number;
    plOtherFinancialCosts?: number;
    plOtherFinancialIncome?: number;
    plOtherOperatingExpenses?: number;
    plOtherOperatingIncome?: number;
    plPersonalCosts?: number;
    plProfitGroupAndAssociatedCompanies?: number;
    plProfitLossAfterFinItems?: number;
    plRawMatAndCons?: number;
    plResearchAndDevCosts?: number;
    plSales?: number;
    plSellingExpenses?: number;
    plShareholderContribution?: number;
    plTaxes?: number;
    plWorkPerfOwnUseCapital?: number;
    realEstateMortgage?: number;
    salaryBoard?: number;
    tantiemBoard?: number;
    toDate?: string;
    totalCollateral?: number;
    totalContingentLiabilities?: number;
  }[];
  status: {
    code: number;
    text: string;
  };
}

type AggregatedFields = {
  [key: string]: unknown;
  field: string;
};

const FinancialData = () => {
  const {
    data: financialData,
    handlePurchase,
    isLoading,
  } = useEntityData<IFinancialData>({ source: 'financial-data', getAction: actions.BUSINESS_GET_FINANCIAL_DATA });

  const [kycTab, setKycTab] = useState(0);

  const handleKycTabChange = useCallback((_event: React.SyntheticEvent, newValue: number) => {
    setKycTab(newValue);
  }, []);

  if (financialData && isArray(financialData.records)) {
    const recordsWithDate = financialData.records.filter((record) => record?.fromDate);
    const keyFinancialData = overviewFinancialFields.map((field) => recordsWithDate
      .reduce<AggregatedFields>((acc, record) => ({
      ...acc,
      [DateTime.fromFormat(record.fromDate!, 'yyyy-mm-dd').year.toString()]: record[field.id],
    }), { field: field.name }));

    const keyFinancialRatiosData = overviewKeyRatiosFields.map((field) => recordsWithDate
      .reduce<AggregatedFields>((acc, record) => ({
      ...acc,
      [DateTime.fromFormat(record.fromDate!, 'yyyy-mm-dd').year.toString()]: record[field.id],
    }), { field: field.name }));

    const allFinancialData = financialDataFields.map((field) => recordsWithDate
      .reduce<AggregatedFields>((acc, record) => ({
      ...acc,
      [DateTime.fromFormat(record.fromDate!, 'yyyy-mm-dd').year.toString()]: record[field.id],
    }), { field: field.name }));

    const dates = Object.keys(keyFinancialData[0])
      .filter((field) => field !== 'field')
      .filter((item) => item)
      .reverse();

    const currency = financialData?.records[0]?.currency ?? 'SEK';

    return (
      <Grid container spacing={6}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <SubSubNavigation>
              <StyledTabs value={kycTab} onChange={handleKycTabChange}>
                <StyledSubTab label="Overview" />
                <StyledSubTab label="Detailed view" />
              </StyledTabs>
            </SubSubNavigation>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <PurchaseButton
            loading={isLoading}
            label="Get Financial Information"
            onClick={handlePurchase}
            action={actions.BUSINESS_CREATE_FINANCIAL_DATA}
          />
        </Grid>
        { kycTab === 0 && (
          <Grid item xs={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <CardHeader
                    title="Financial summary"
                    subheader={(
                      <Box display="flex" alignItems="baseline">
                        <Typography>Latest update:</Typography>
                        <DateField
                          record={{ createdAt: financialData.createdAt }}
                          sx={{ marginLeft: 1, fontWeight: 'bold' }}
                          source="createdAt"
                          emptyText="-"
                          showTime
                        />
                      </Box>
                    )}
                  />
                  <Divider />
                  <Grid container spacing={0}>
                    <Grid item xs={8} md={8}>
                      <FinancialSummary data={financialData} />
                    </Grid>
                    <Grid item>
                      <Divider orientation="vertical" />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Card>
                        <CardHeader title={`Key Financial (${dates[0]} - ${dates.slice(-1)[0]}) K${currency}`} />
                        <CardContent>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={12}>
                              <Box display="flex" justifyContent="space-between">
                                <Typography>Sales</Typography>
                                <NumberField
                                  record={{
                                    sales: financialData.records
                                      .map((record) => record.plSales)
                                      .filter((item) => !!item)
                                      .reduce((a, b) => a! + b!, 0),
                                  }}
                                  source="sales"
                                  emptyText="-"
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Box display="flex" justifyContent="space-between">
                                <Typography>Net Income</Typography>
                                <NumberField
                                  record={{
                                    netIncome: financialData.records
                                      .map((record) => record.bsNetIncome)
                                      .filter((item) => !!item)
                                      .reduce((a, b) => a! + b!, 0),
                                  }}
                                  source="netIncome"
                                  emptyText="-"
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Box display="flex" justifyContent="space-between">
                                <Typography>Equity</Typography>
                                <NumberField
                                  record={{
                                    equity: financialData.records
                                      .map((record) => record.bsTotalEquity)
                                      .filter((item) => !!item)
                                      .reduce((a, b) => a! + b!, 0),
                                  }}
                                  source="equity"
                                  emptyText="-"
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Box display="flex" justifyContent="space-between">
                                <Typography>Total Assets</Typography>
                                <NumberField
                                  record={{
                                    assets: financialData.records
                                      .map((record) => record.bsTotalAssets)
                                      .filter((item) => item)
                                      .reduce((a, b) => a! + b!, 0),
                                  }}
                                  source="assets"
                                  emptyText="-"
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <CardHeader
                    title={`Key Financial data (K${currency})`}
                    subheader={(
                      <Box display="flex" alignItems="baseline">
                        <Typography>Latest update:</Typography>
                        <DateField
                          record={{ createdAt: financialData?.createdAt }}
                          sx={{ marginLeft: 1, fontWeight: 'bold' }}
                          source="createdAt"
                          emptyText="-"
                          showTime
                        />
                      </Box>
                    )}
                    action={(
                      <Typography variant="smallTitle">
                        { `Last ${Number.parseInt(dates[0], 10) - Number.parseInt(dates.slice(-1)[0], 10) + 1} years`}
                      </Typography>
                    )}
                  />
                  <Divider />
                  <ArrayField record={{ records: keyFinancialData }} source="records">
                    <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                      <TextField source="field" label="" />
                      {dates.map((date) => (
                        <NumberField emptyText="-" key={date} source={date} label={date} />
                      ))}
                    </Datagrid>
                  </ArrayField>
                </Card>
              </Grid>
              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <CardHeader
                    title="Key Financial Ratios (%)"
                    action={<Typography variant="smallTitle">{ `Last ${Number.parseInt(dates[0], 10) - Number.parseInt(dates.slice(-1)[0], 10) + 1} years`}</Typography>}
                    subheader={(
                      <Box display="flex" alignItems="baseline">
                        <Typography>Latest update:</Typography>
                        <DateField
                          record={{ createdAt: financialData?.createdAt }}
                          sx={{ marginLeft: 1, fontWeight: 'bold' }}
                          source="createdAt"
                          emptyText="-"
                          showTime
                        />
                      </Box>
                    )}
                  />
                  <Divider />
                  <ArrayField record={{ records: keyFinancialRatiosData }} source="records">
                    <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                      <TextField source="field" label="" />
                      {dates?.map((date) => (
                        <NumberField emptyText="-" key={date} source={date} label={date} />
                      ))}
                    </Datagrid>
                  </ArrayField>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )}
        { kycTab === 1 && (
          <Grid item xs={12} md={12}>
            <Card variant="outlined">
              <CardHeader title="Financial data (KSEK)" action={<Typography variant="smallTitle">{ `Last ${Number.parseInt(dates[0], 10) - Number.parseInt(dates.slice(-1)[0], 10) + 1} years`}</Typography>} />
              <Divider />
              <ArrayField record={{ records: allFinancialData }} source="records">
                <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                  <TextField source="field" label="" />
                  {dates?.map((date) => (
                    <NumberField emptyText="-" key={date} source={date} label={date.substring(0, 4)} />
                  ))}
                </Datagrid>
              </ArrayField>
            </Card>
          </Grid>
        )}
      </Grid>
    );
  }
  return (
    <Fade in={!financialData?.records}>
      <Box margin={5} display="flex" alignItems="center" width="100%" flexDirection="column">
        <Typography variant="smallTitle">No Data Available</Typography>
        <PurchaseButton action={actions.BUSINESS_CREATE_FINANCIAL_DATA} loading={isLoading} label="Get Financial Data" empty onClick={handlePurchase} />
      </Box>
    </Fade>
  );
};

export default FinancialData;
